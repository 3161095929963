import c from 'classnames';
import xss from 'xss';

type RichTextProps = {
  html: string;
  className?: string;
};

/**
 * Renders rich text content as HTML.
 */
export const RichText = ({ html, className = '', ...props }: RichTextProps) => {
  return (
    <div
      className={c(
        '[&_a]:body-small-link body-small [&_h2]:header-mid [&_h3]:header-small [&_h4]:header-xsmall text-grey-900 [&_a]:text-blue-dark [&_h2]:mb-5 [&_h3]:mb-5 lg:[&_h3]:mb-2 [&_li>p]:mb-1 [&_li]:ml-8 [&_p]:mb-4 [&_ul]:mb-5 [&_ul]:list-disc',
        className
      )}
      dangerouslySetInnerHTML={{ __html: xss(html) }}
      {...props}
    />
  );
};
